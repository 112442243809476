<template>
  <div class="deload-workouts">
    <div v-if="workouts.length" class="my-5">
      <v-card
        v-for="(grouping, groupIndex) in groupedWorkouts"
        :key="groupIndex"
        class="mb-8"
        outlined
      >
        <v-card-title class="primary lighten-4">
          Week {{ groupIndex }}</v-card-title
        >

        <template v-for="(workout, workoutIndex) in grouping">
          <div class="pa-5" :key="workoutIndex">
            <router-link
              class="row clickable"
              :to="{
                name: 'workout.details',
                params: { id: workout.id },
                meta: { stage: 1 },
              }"
            >
              <v-col cols="2">
                <v-chip
                  :color="getDayColor(workout.day)"
                  text-color="white"
                  small
                >
                  {{ getDay(workout.day) }}
                </v-chip>
              </v-col>
              <v-col cols="3"> {{ workout.title }} </v-col>
              <v-col cols="4"> {{ workout.description }} </v-col>
              <v-col cols="3">
                <v-btn
                  class="float-right"
                  color="secondary"
                  :loading="loading"
                  @click="removeWorkout($event, workout)"
                  text
                >
                  Remove
                </v-btn>
              </v-col>
            </router-link>
          </div>

          <v-divider :key="`divider-${workoutIndex}`" />
        </template>
      </v-card>
    </div>

    <no-list v-else details="No Available Deload Workouts" />

    <confirm-dialog ref="confirmDelete" />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import NoList from '@/components/elements/NoList'
import Challenge from '@/models/Challenge'
import Program from '@/models/Program'
import Workout from '@/models/Workout'

import { map, sortBy, groupBy, mapValues } from 'lodash'
import { mapMutations } from 'vuex'

export default {
  name: 'DeloadWorkouts',

  components: {
    ConfirmDialog,
    NoList,
  },

  props: {
    model: {
      type: Object,
      required: true,
    },

    deloadWorkouts: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      workouts: [],
      loading: false,
      modelType: this.model.type,
    }
  },

  computed: {
    isChallenge() {
      return this.modelType === 2
    },

    modelClass() {
      return this.isChallenge
        ? new Challenge(this.model)
        : new Program(this.model)
    },

    groupedWorkouts() {
      let _workouts = map(this.workouts, (workout) => {
        return {
          ...workout,
          ...{
            day: workout.program_workout.day,
            week: workout.program_workout.week,
            program_workout_id: workout.program_workout.id,
          },
        }
      })

      let _groupedByWeek = groupBy(_workouts, 'week')

      return mapValues(_groupedByWeek, (workout, index) => {
        return sortBy(workout, ['day'])
      })
    },
  },

  created() {
    this.workouts = this.deloadWorkouts
  },

  methods: {
    ...mapMutations({
      removeProgramDeloadWorkout: 'programs/removeProgramDeloadWorkout',
      removeChallengeDeloadWorkout: 'challenges/removeChallengeDeloadWorkout',
    }),

    getDay(index) {
      let days = [
        'SUNDAY',
        'MONDAY',
        'TUESDAY',
        'WEDNESDAY',
        'THURSDAY',
        'FRIDAY',
        'SATURDAY',
      ]

      return days[index]
    },

    getDayColor(index) {
      let colors = [
        'blue-grey',
        'blue',
        'orange',
        'pink',
        'green',
        'purple',
        'teal',
      ]

      return colors[index]
    },

    async removeWorkout($event, workout) {
      event.preventDefault()

      const confirm = await this.$refs.confirmDelete.open(
        'Remove workout',
        'Are you sure you want to remove this workout?'
      )

      if (!confirm) return

      this.loading = true

      const programWorkout = new Workout({ id: workout.program_workout_id })

      await programWorkout.for(this.model).delete()

      if (this.isChallenge) {
        this.removeChallengeDeloadWorkout(workout)
      } else {
        this.removeProgramDeloadWorkout(workout)
      }

      this.loading = false
    },
  },

  watch: {
    model(value) {
      this.modelType = value.type
    },

    deloadWorkouts(values) {
      this.workouts = values
    },
  },
}
</script>

<style lang="scss" scoped>
.border-bottom {
  border: solid;
  border-width: 0 0 thin 0;
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.clickable {
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.6);
}
</style>
