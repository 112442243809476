<template>
  <div class="bbr-program--deload-workout-form">
    <v-row>
      <v-col cols="12" sm="4">
        <search-workout
          v-model="form.workout_id"
          :trainer-id="model.trainer_id"
          :errors="form.$getError('workout_id')"
          with-description
          return-object
          workout-only
          with-tags
          completed
        />
      </v-col>

      <v-col cols="12" sm="2">
        <v-select
          v-model="form.week"
          :items="deloadWeeks"
          :error-messages="form.$getError('week')"
          label="Week"
          item-value="id"
          item-text="name"
          :hide-details="!form.$getError('week')"
          outlined
          flat
        />
      </v-col>

      <v-col cols="12" sm="3">
        <v-select
          v-model="form.day"
          :items="days"
          :error-messages="form.$getError('day')"
          label="Day"
          item-value="id"
          item-text="name"
          :hide-details="!form.$getError('day')"
          outlined
          flat
        />
      </v-col>

      <v-col cols="12" sm="3">
        <v-btn color="primary" @click="addWorkoutToChallenge" block x-large>
          <v-icon class="mr-3"> {{ icons.add }} </v-icon>
          Add Workout
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SearchWorkout from '@/components/elements/workouts/SearchWorkoutsInput'
import Challenge from '@/models/Challenge'
import Program from '@/models/Program'
import Workout from '@/models/Workout'
import Form from '@/utils/form'

import { mapMutations } from 'vuex'
import { mdiPlus } from '@mdi/js'

export default {
  name: 'DeloadWorkoutForm',

  components: {
    SearchWorkout,
  },

  props: {
    model: {
      type: Object,
      required: true,
    },

    deloadWeeks: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      days: [
        { id: 0, name: 'SUNDAY' },
        { id: 1, name: 'MONDAY' },
        { id: 2, name: 'TUESDAY' },
        { id: 3, name: 'WEDNESDAY' },
        { id: 4, name: 'THURSDAY' },
        { id: 5, name: 'FRIDAY' },
        { id: 6, name: 'SATURDAY' },
      ],

      modelType: this.model.type,

      icons: {
        add: mdiPlus,
      },

      form: new Form({
        day: null,
        week: null,
        is_deload: true,
        workout_id: null,
      }),
    }
  },

  computed: {
    isChallenge() {
      return this.modelType === 2
    },

    modelClass() {
      return this.isChallenge
        ? new Challenge(this.model)
        : new Program(this.model)
    },
  },

  methods: {
    ...mapMutations({
      setProgramDeloadWorkout: 'programs/setSelectedProgramDeloadWorkout',
      setChallengeDeloadWorkout: 'challenges/setSelectedChallengeDeloadWorkout',
    }),

    async addWorkoutToChallenge() {
      this.form.$busy = true

      try {
        let workout = new Workout(this.form).for(this.modelClass)

        let { data } = await workout.save()

        if (this.isChallenge) {
          this.setChallengeDeloadWorkout(data)
        } else {
          this.setProgramDeloadWorkout(data)
        }

        this.form.$reset()
      } catch ({ response }) {
        this.form.$timeout(() => {
          this.form.$busy = false

          if (response && response.status === 422) {
            this.form.$setErrors(response.data.errors)
          }
        })
      }
    },
  },

  watch: {
    model(value) {
      this.modelType = value.type
    },
  },
}
</script>
